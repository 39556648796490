const StartProductionIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8V13"
        stroke="#8283BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25"
        stroke="#8283BE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 2H15"
        stroke="#8283BE"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9004 18.5V17.34C14.9004 15.91 15.9204 15.32 17.1604 16.04L18.1604 16.62L19.1604 17.2C20.4004 17.92 20.4004 19.09 19.1604 19.81L18.1604 20.39L17.1604 20.97C15.9204 21.69 14.9004 21.1 14.9004 19.67V18.5Z"
        stroke="#8283BE"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export { StartProductionIcon };
